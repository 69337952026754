import React from 'react'

import {contactUsPropTypes} from 'helpers/propTypes'
import LocationCards from 'components/ContactUs/LocationCards'
import Section from 'components/UI/Section'
import SwitchForm from 'components/UI/SwitchForm'

import useStyles from './styles'

const ContactUsSection = ({
  data,
  faxText,
  formData,
  locale,
  location,
  locationState,
  phoneText,
  redirect,
  websiteSource,
  hubSpotCountry,
  formId,
}) => {
  const classes = useStyles()

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.wrapper}>
        <div className={classes.form}>
          <SwitchForm
            data={formData}
            location={location}
            locale={locale}
            redirection={redirect}
            redirect={redirect}
            websiteSource={websiteSource}
            hubSpotCountry={hubSpotCountry}
            isContact
            locationState={locationState}
            formId={formId}
          />
        </div>
        <div className={classes.cards}>
          <LocationCards data={data} phoneText={phoneText} faxText={faxText} />
        </div>
      </div>
    </Section>
  )
}

ContactUsSection.propTypes = contactUsPropTypes.isRequired

export default ContactUsSection
